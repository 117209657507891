/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  fetchTutors({ commit }, dataTableParams) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/admin/tutor",{params: dataTableParams})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error) })
    })
  },
  fetchTutorById(context, tutorId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/admin/tutor/${tutorId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateTutorById(context, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/v1/admin/tutor/${data.tutorId}`, data.tutorData)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteUserById(context,data){
    return new Promise((resolve, reject) => {
      axios.post(`/api/v1/admin/tutor/delete/${data.tutorId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getLanguages(context) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/tutor/getLanguages`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getSubjects(context) {
    let dataTableParams = {limit:500}
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/tutor/getSubjects`,{params:dataTableParams})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeRecord({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/user-management/users/${userId}`)
        .then((response) => {
          commit('REMOVE_RECORD', userId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
