import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const auth = (to, from, next) => {
  const token = localStorage.getItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
  );
  const isLoggedIn = localStorage.getItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`
  );
  const cookieToken = Vue.$cookies.get(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RememberMeToken`
  );
  const cookieUser = Vue.$cookies.get(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}UserType`
  );

  const publicPages = [
    "/admin/login",
    "/register",
    "/admin/forgot-password",
    "/verify-email/" + to.params.token,
    "/set-password/" + to.params.token,
    "/reset-password/" + to.params.token,
    "/pages/error-419",
    "/pages/error-404",
  ];

  const authRequired = !publicPages.includes(to.path);
  console.log(authRequired);

  if (!authRequired && isLoggedIn && token) {
    const user = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    console.log(user);
    if (user.userType === "admin" || user.userType === "superAdmin") {
      return next("/admin/dashboard");
    }
  }

  if (authRequired && !isLoggedIn && !token) {
    if (from.path !== "/admin/login") {
      return next("/admin/login");
    }
  }

  if (!publicPages.includes(to.path) && token) {
    const user = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if (typeof user.userType === "undefined") {
      return next("/admin/login");
    }
    if (user.userType === "admin" || user.userType === "superAdmin") {
      if (!to.path.includes("/admin/")) {
        return next("/pages/error-404");
      }
    } else {
      if (!to.path.includes("/" + user.userType + "/")) {
        return next("/pages/error-404");
      }
    }
  }
  return next();
};
