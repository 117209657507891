import axios from "../../../axios/index.js"
import store from "../../../../store/store.js"
import Vue from 'vue'
// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(refreshData) {
  // subscribers = subscribers.filter(callback => callback(access_token))
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`,
    refreshData.accessToken
  );
  // localStorage.setItem(
  //   `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
  //   refreshData.user.firstName
  // );
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
    JSON.stringify(refreshData.user)
  );
  Vue.$cookies.set(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`,
    refreshData.refreshToken,
    refreshData.refreshTokenExpiresIn,
    "",
    "stage.freshclinics.com.au",
    true
  );
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`,
    "true"
  );
  store.commit("UPDATE_USER_INFO", refreshData.user, { root: true });
  store.commit("SET_BEARER", refreshData.accessToken);
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export default {
  // init() {
  //   axios.interceptors.response.use(function (response) {
  //     return response
  //   }, function (error) {
  //     // const { config, response: { status } } = error
  //     const { config, response } = error
  //     const originalRequest = config
  //
  //     // if (status === 401) {
  //     if (response && response.status === 401) {
  //       if (!isAlreadyFetchingAccessToken) {
  //         isAlreadyFetchingAccessToken = true
  //         store.dispatch("auth/fetchAccessToken")
  //           .then((access_token) => {
  //             isAlreadyFetchingAccessToken = false
  //             onAccessTokenFetched(access_token)
  //           })
  //       }
  //
  //       const retryOriginalRequest = new Promise((resolve) => {
  //         addSubscriber(access_token => {
  //           originalRequest.headers.Authorization = 'Bearer ' + access_token
  //           resolve(axios(originalRequest))
  //         })
  //       })
  //       return retryOriginalRequest
  //     }
  //     return Promise.reject(error)
  //   })
  // },
  init() {
    axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
        );
        if (token) {
          config.headers.Authorization = token;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        const {
          config,
          response: { status }
        } = error;

        if (config.url.includes("login") && status === 401) {
          return Promise.reject(error);
        }

        if (config.url.includes("refreshToken") && status === 401) {
          router.push("login");
        }

        const originalRequest = config;
        // if (status === 401) {
        if (status === 403) {
          localStorage.clear();
          // router.push("/login");
          if (!isAlreadyFetchingAccessToken) {
            console.log('fdsfasd');
            isAlreadyFetchingAccessToken = true;
            store
              .dispatch("auth/fetchAccessToken")
              .then(refreshTokenResponse => {
                isAlreadyFetchingAccessToken = false;
                onAccessTokenFetched(refreshTokenResponse.data.data);
                originalRequest.headers.Authorization =
                  "Bearer " + refreshTokenResponse.data.data.accessToken;
                return Promise.resolve(axios(originalRequest));
              });
          }
        }
        console.log('reject');
        return Promise.reject(error);
      }
    );
  },
  login(email, pwd,rememberMe) {
    return axios.post("/api/v1/admin/login", {email: email, password: pwd,rememberMe: rememberMe})
  },
 /* registerUser(name, email, pwd) {
    return axios.post("/auth/register", {displayName: name, email: email, password: pwd})
  },*/
  resetPassword(password, token) {
    return axios.post('/api/v1/auth/resetPassword', {password: password, token: token})
  },
  forgotPasswordAdmin(email) {
    return axios.post("/api/v1/auth/forgotPassword", { email: email });
  },
  checkForValidToken(token) {
    return axios.post('/api/v1/auth/checkForValidToken', {token: token})
  },
  refreshToken() {
    console.log('refresh');
    return axios.post("/api/v1/auth/refreshToken", {
      HasCredRemembered: Vue.$cookies.get(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}HasCredRemembered`
      ),
      refreshToken: Vue.$cookies.get(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
      )
    });
    // return axios.post("/api/v1/auth/refreshToken", {
    //   refreshToken: localStorage.getItem("refreshToken")
    // })
  },
  changePassword(currentPassword, newPassword) {
    return axios.post("/api/v1/user/changePassword", {
      oldPassword: currentPassword,
      newPassword: newPassword
    });
  }
}
