/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import { auth } from "@/auth/auth";
import store from "./store/store";

import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
      {
        // =============================================================================
        // MAIN LAYOUT ROUTES
        // =============================================================================
        path: "",
        component: () => import("./layouts/main/Main.vue"),
        children: [
          // =============================================================================
          // Theme Routes
          // =============================================================================
          {
            path: "/",
            redirect: "/admin/login"
          }],
      },

        /****** Admin Portal ********/
        {
            path:'/admin',
            redirect:'/admin/pages',
            component: () => import("./layouts/main/Main.vue"),
            meta: {
                rule: 'editor',
            },
            children:[
              {
                path: 'dashboard',
                name: 'CMSDashboard',
                component: () => import('./views/admin/Dashboard.vue'),
                meta: {
                  pageTitle:'SuperTutor | Dashboard',
                  rule: 'editor',
                    authRequired:true
                }
              },
              {
                path: "change-password",
                name: "AdminChangePassword",
                component: () => import("./views/pages/ChangePassword.vue"),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/admin/dashboard" },
                    { title: "Change Password", active: true }
                  ],
                  pageTitle: "Super Tutor | Change Password",
                  rule: "editor"
                }
              },
              {
                path: "success",
                name: "AdminSuccess",
                component: () => import("@/views/pages/Success.vue"),
                meta: {
                  rule: "editor"
                }
              },
                {
                    path:'pages',
                    name:'CMSPage',
                    component: () => import('./views/cms-pages/CMSPage.vue'),
                    meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "Page", active: true }
                      ],
                        pageTitle:'Pages',
                        rule: 'editor',
                    }
                },
              {
                path:'reports',
                name:'Reports',
                component: () => import('./views/admin/report/Index.vue'),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/admin/dashboard" },
                    { title: "Reported Issues", active: true }
                  ],
                  pageTitle:'Reported Issues',
                  rule: 'editor',
                }
              },
              {
                path:'transactions',
                name:'TransactionList',
                component: () => import('./views/admin/transaction/Index.vue'),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/admin/dashboard" },
                    { title: "Transaction List", active: true }
                  ],
                  pageTitle:'Transactions',
                  rule: 'editor',
                }
              },
              {
                path:'transactions/:bookingId',
                name:'TransactionDetail',
                component: () => import('./views/admin/transaction/Detail.vue'),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/admin/dashboard" },
                    { title: "Transaction List", url: "/admin/transactions" },
                    { title: "View", active: true }
                  ],
                  pageTitle:'Transaction Details',
                  rule: 'editor',
                }
              },
                {
                    path:'pages/:id',
                    name:'CMSPageDetail',
                    component: () => import('./views/cms-pages/CMSPageDetail.vue'),
                    meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "Pages", url: "/admin/pages" },
                        { title: "View", active: true }
                      ],
                        pageTitle:'Page Details',
                        rule: 'editor',
                    }
                },
                /************* Admin Management ***********/
                {
                    path:'admin-management',
                    name:'Admins',
                    component: () => import('./views/admin/AdminManagement.vue'),
                    meta: {
                        breadcrumb: [
                          { title: "Home", url: "/admin/dashboard" },
                          { title: "Admin list", active: true }
                        ],

                        pageTitle:'Admins',
                        rule: 'editor',
                    }
                },

                {
                  path:'admin-management/add',
                  name:'AddAdmin',
                  component: () => import('./views/admin/AdminAdd.vue'),
                  meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "Admin list", url: "/admin/admin-management" },
                        { title: "Add", active: true }
                      ],
                      pageTitle:'Add Admin',
                      rule: 'editor',
                  }
                },

                {
                  path:'admin-management/edit/:adminId',
                  name:'EditAdmin',
                  component: () => import('./views/admin/AdminAdd.vue'),
                  meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "Admin list", url: "/admin/admin-management" },
                        { title: "Update", active: true }
                      ],
                      pageTitle:'Edit Admin',
                      rule: 'editor',
                  }
                },

                {
                  path:'admin-management/view/:adminId',
                  name:'ViewAdmin',
                  component: () => import('./views/admin/AdminVue.vue'),
                  meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "Admin list", url: "/admin/admin-management" },
                        { title: "View", active: true }
                      ],
                      pageTitle:' Admin Details',
                      rule: 'editor',
                  }
                },
                /************* end of admin Management ***********/
                /************* Tutor Management ***********/
                {
                    path:'tutors',
                    name:'Tutors',
                    component: () => import('./views/tutor/TutorManagement.vue'),
                    meta: {
                        breadcrumb: [
                          { title: "Home", url: "/admin/dashboard" },
                          { title: "Tutor list", active: true }
                        ],

                        pageTitle:'Tutors',
                        rule: 'editor',
                    }
                },

                {
                  path:'tutors/add',
                  name:'AddTutor',
                  component: () => import('./views/tutor/TutorAdd.vue'),
                  meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "Tutor list", url: "/admin/tutors" },
                        { title: "Add", active: true }
                      ],
                      pageTitle:'Add Tutor',
                      rule: 'editor',
                  }
              },
                {
                    path:'tutors/:tutorId',
                    name:'TutorDetails',
                    component: () => import('./views/tutor/TutorDetails.vue'),
                    meta: {
                        breadcrumb: [
                          { title: "Home", url: "/admin/dashboard" },
                          { title: "Tutor list", url: "/admin/tutors" },
                          { title: "View", active: true }
                        ],
                        pageTitle:'Tutor Details',
                        rule: 'editor',
                    }
                },
                {
                    path:'tutors/edit/:tutorId',
                    name:'EditTutorDetails',
                    component: () => import('./views/tutor/TutorEdit.vue'),
                    meta: {
                        breadcrumb: [
                          { title: "Home", url: "/admin/dashboard" },
                          { title: "Tutor list", url: "/admin/tutors" },
                          { title: "Edit", active: true }
                        ],
                        pageTitle:'Edit Tutor Details',
                        rule: 'editor',
                    }
                },
                /*************end of Tutor Management ***********/

                /************* User Management ***********/
                {
                  path:'users',
                  name:'Users',
                  component: () => import('./views/user/UserManagement.vue'),
                  meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "User list", active: true }
                      ],

                      pageTitle:'Users',
                      rule: 'editor',
                  }
              },
              {
                path:'users/add',
                name:'AddUser',
                component: () => import('./views/user/UserAdd.vue'),
                meta: {
                    breadcrumb: [
                      { title: "Home", url: "/admin/dashboard" },
                      { title: "User list", url: "/admin/users" },
                      { title: "Add", active: true }
                    ],
                    pageTitle:'Add User',
                    rule: 'editor',
                }
            },
              {
                  path:'users/:userId',
                  name:'UserDetails',
                  component: () => import('./views/user/UserDetails.vue'),
                  meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "User list", url: "/admin/users" },
                        { title: "View", active: true }
                      ],
                      pageTitle:'User Details',
                      rule: 'editor',
                  }
              },
              {
                  path:'users/edit/:userId',
                  name:'EditUserDetails',
                  component: () => import('./views/user/UserEdit.vue'),
                  meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "User list", url: "/admin/users" },
                        { title: "Edit", active: true }
                      ],
                      pageTitle:'Edit User Details',
                      rule: 'editor',
                  }
              },
              /*************end of User Management ***********/

                /************* Subject Management ***********/
                {
                  path:'subjects',
                  name:'Subjects',
                  component: () => import('./views/subjects/SubjectManagement.vue'),
                  meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "Subject list", active: true }
                      ],

                      pageTitle:'Subjects',
                      rule: 'editor',
                  }
              },
              /*************end of Subject Management ***********/
              {
                path:'site-setting',
                name:'Setting',
                component: () => import('./views/admin/setting/Update.vue'),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/admin/dashboard" },
                    { title: "Settings", active: true }
                  ],

                  pageTitle:'Settings',
                  rule: 'editor',
                }
              },

                /************* Language Management ***********/
                {
                  path:'languages',
                  name:'Languages',
                  component: () => import('./views/languages/LanguageManagement.vue'),
                  meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "Language list", active: true }
                      ],

                      pageTitle:'Languages',
                      rule: 'editor',
                  }
              },
              /*************end of Language Management ***********/

                /************* Level Management ***********/
                {
                  path:'levels',
                  name:'Levels',
                  component: () => import('./views/levels/LevelManagement.vue'),
                  meta: {
                      breadcrumb: [
                        { title: "Home", url: "/admin/dashboard" },
                        { title: "Level list", active: true }
                      ],

                      pageTitle:'Levels',
                      rule: 'editor',
                  }
              },
              /*************end of Level Management ***********/
              /************* Report Title Management ***********/
              {
                path:'report-titles',
                name:'ReportTitles',
                component: () => import('./views/admin/report-title/ReportTitleManagement.vue'),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/admin/dashboard" },
                    { title: "Report list", active: true }
                  ],

                  pageTitle:'Report',
                  rule: 'editor',
                }
              },
              /*************end of Report Title Management ***********/
              /****EMAIL TEMPLATE MANAGEMENT ROUTE STARTED****/
              {
                path: "email-templates",
                name: "email-template",
                component: () => import("@/views/email/EmailTemplate.vue"),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/admin/dashboard" },
                    { title: "List", active: true }
                  ],
                  pageTitle: "Email Templates",
                  rule: "editor"
                }
              },
              {
                path: "email-templates/:id",
                name: "admin-edit-email-template",
                component: () => import("@/views/email/EditEmailTemplate.vue"),
                meta: {
                  breadcrumb: [
                    { title: "Home", url: "/admin/dashboard" },
                    { title: "Email Templates", url: "/admin/email-templates" },
                    { title: "Edit", active: true }
                  ],
                  pageTitle: "Email Template Detail",
                  rule: "editor"
                }
              },
              //end of email template mgmt
            ]
        },
        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
                {
                    path: '/',
                    redirect: '/admin/dashboard'
                },
              {
                path: '/admin/profile',
                name: 'admin-profile',
                component: () => import('@/views/admin/user/Profile.vue'),
                meta: {
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Profile' },
                    { title: 'Profile', active: true },
                  ],
                  pageTitle: 'Profile',
                  rule: 'editor'
                },
              },

            ],
        },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
        // =============================================================================
        // PAGES
        // =============================================================================
                {
                    path: '/verify/:token',
                    name: 'Email Verification',
                    component: () => import('@/views/pages/EmailVerificationSuccess.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/admin/login',
                    name: 'page-login',
                    component: () => import('@/views/pages/login/Login.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                /*{
                    path: '/pages/register',
                    name: 'page-register',
                    component: () => import('@/views/pages/register/Register.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },*/
                {
                    path: '/admin/forgot-password',
                    name: 'page-forgot-password',
                    component: () => import('@/views/pages/ForgotPassword.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/reset-password/:token',
                    name: 'reset-password',
                    beforeEnter: (to, from, next) => {
                        const token = to.params.token
                          store
                            .dispatch("auth/verifyToken", token)
                            .then(() => {
                              next();
                            })
                            .catch(error => {
                              next("/pages/error-419");
                            });
                      },
                    component: () => import('@/views/pages/ResetPassword.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/error-404',
                    name: 'page-error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
              {
                path: "/pages/error-419",
                name: "page-error-419",
                component: () => import("@/views/pages/Error419.vue"),
                meta: {
                  rule: "editor"
                }
              },
                {
                    path: '/pages/error-500',
                    name: 'page-error-500',
                    component: () => import('@/views/pages/Error500.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/not-authorized',
                    name: 'page-not-authorized',
                    component: () => import('@/views/pages/NotAuthorized.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

router.beforeEach((to, from, next) => {

    const exceptRoute = ["/verify/" + to.params.token,"/verify-email/" + to.params.token,"/reset-password/"+to.params.token,"/pages/error-419"];
    if((JSON.parse(localStorage.getItem("userInfo"))) && (JSON.parse(localStorage.getItem("userInfo")).userType) == 'admin' && to.path == '/admin/admin-management' || to.path =='/admin/admin-management/*'){
      return next('dashboard')
    }
    console.log('heree')
    if (!exceptRoute.includes(to.path)) {
      auth(to, from, next);
      return next();
    } else {
      return next();
    }

});

export default router
