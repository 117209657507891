/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  fetchSubjects({ commit },dataTableParams) {

    return new Promise((resolve, reject) => {
      axios.get("/api/v1/tutor/getSubjects",{params: dataTableParams})
        .then((response) => {
          commit('SET_USERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addSubjects({ commit },data) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/admin/subject/add",data)
        .then((response) => {
          commit('SET_USERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeSubjects({ commit },data) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/admin/subject/remove",data)
        .then((response) => {
          commit('SET_USERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeChildrenSubject({ commit },data) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/admin/subject/remove/children",data)
        .then((response) => {
          commit('SET_USERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateSubjectById({ commit },data) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/admin/subject/update",data)
        .then((response) => {
          commit('SET_USERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
