import axios from '@/axios';

export default {
    fetchTransactions({ commit },data) {
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/admin/transactions?page='+data.page+'&limit='+data.limit+'&search='+data.search+'&sort='+data.sort+'&dir='+data.dir)
              .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },
  fetchBookingTransactionById(context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/admin/transactions/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },



}
