import axios from '@/axios';

export default {
    getDashboardData({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/admin/dashboard')
              .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },
    fetchLanguages({ commit }, dataTableParams) {
        return new Promise((resolve, reject) => {
            axios.get("/api/v1/tutor/getLanguages",{params: dataTableParams})
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },
  fetchSetting({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/admin/setting')
        .then((res) => {
          resolve(res);
        }).catch((err) => {
        reject(err)
      });
    })
  },
  updateSetting({commit},data){
    return new Promise((resolve,reject)=>{
      axios.post('/api/v1/admin/setting/update',data).then((res) => {
        resolve(res);
      }).catch(err =>{
        reject(err)
      })
    })
  },

    addLanguage({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post("/api/v1/admin/languages/add", data)
                .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },

    removeLanguage({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post("/api/v1/admin/languages/remove", data)
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },

    updateLanguage({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post("/api/v1/admin/languages/update", data)
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },
    fetchLevels({ commit }, dataTableParams) {
        return new Promise((resolve, reject) => {
            axios.get("/api/v1/tutor/getEducationTitle",{params: dataTableParams})
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },

    addLevel({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post("/api/v1/admin/levels/add", data)
              .then((res) => {
              resolve(res);
          }).catch((err) => {
              reject(err)
          });
      })
  },

  removeLevel({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post("/api/v1/admin/levels/remove", data)
          .then((res) => {
              resolve(res);
          }).catch((err) => {
              reject(err)
          });
      })
  },

  updateLevel({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post("/api/v1/admin/levels/update", data)
          .then((res) => {
              resolve(res);
          }).catch((err) => {
              reject(err)
          });
      })
  },
}
