/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleAuth from './auth/moduleAuth.js'
import modulePage from "./page/modulePage.js";
import moduleTutor from "./tutor-management/moduleTutorManagement.js";
import moduleUser from "./user-management/moduleUserManagement.js";
import moduleSubject from "./subject-management/moduleSubjectManagement.js";
import moduleReport from "./report/moduleReport";
import moduleTransaction from "./transaction/moduleTransaction"
import moduleGeneral from './general/moduleGeneral'
import moduleEmail from "./email/moduleEmail.js";

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        auth: moduleAuth,
        page: modulePage,
        transaction:moduleTransaction,
        tutor: moduleTutor,
        user: moduleUser,
        subject: moduleSubject,
        report: moduleReport,
        general: moduleGeneral,
        email: moduleEmail,
    },
    strict: process.env.NODE_ENV !== 'production'
})
