// axios
import axios from 'axios'

const domain = process.env.VUE_APP_BASE_API_URL

export default axios.create({
  baseURL: domain

  // You can add your headers here
})
