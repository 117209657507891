/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"
import httpStatusCode from "http-status-codes";
import axios from '@/axios';

/*
import firebase from 'firebase/app'
*/
/*
import 'firebase/auth'
*/
import Vue from "vue";
import router from '@/router'
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

export default {

    // JWT
    loginJWT({ commit }, payload) {

      return new Promise((resolve,reject) => {
        jwt.login(payload.userDetails.email, payload.userDetails.password,payload.rememberMe)
          .then(response => {
            if (response.data) {
              const data=response.data.data;
              const refreshToken = response.headers.refreshtoken;
              const accessToken = response.headers.accesstoken;
              const refreshTokenExpiresIn = response.headers.refreshtokenexpiresin;
              localStorage.setItem(
                `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
                JSON.stringify(data)
              );
              // Set accessToken and refresh token
              localStorage.setItem(
                `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`,
                accessToken
              );
              Vue.$cookies.set(
                `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`,
                refreshToken,
               refreshTokenExpiresIn,
                "",
                "stage.freshclinics.com.au",
                true
              );
              /*  localStorage.setItem(
                `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`,
                data.token.refreshToken
              ); */
              // var date = new Date().getTime() + 60 * 60 * 24 * 1000;
              // localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}TokenExpiry`, date);
              localStorage.setItem(
                `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`,
                "true"
              );

              //set cookie if keep me login
              if (payload.rememberMe === true) {
                Vue.$cookies.set(
                  `${process.env.VUE_APP_ACCESS_TOKEN_NAME}HasCredRemembered`,
                  true,
                  refreshTokenExpiresIn,
                  "",
                  "stage.freshclinics.com.au",
                  true
                );
              }
              // Update user details
              commit("UPDATE_USER_INFO", data, { root: true });
              // commit('UPDATE_USER_INFO', JSON.stringify(response.data), {root: true})
              // commit('UPDATE_USER_INFO', JSON.stringify(response.data), {root: true})

              // Set bearer token in axios
              commit("SET_BEARER", accessToken);

              // Navigate User to homepage
              if (data.userType === "admin" || data.userType === "superAdmin") {
                router.push("/admin/dashboard");
              }
              resolve(response);
            } else {
              console.log('fsdf');
              reject({ message: response.data.message });
            }

          })
          .catch(({ response }) => {
            console.log(response);
            reject({ message: response.data.message });  })
      })
    },
    /*registerUserJWT({ commit }, payload) {

      const { displayName, email, password, confirmPassword } = payload.userDetails

      return new Promise((resolve,reject) => {

        // Check confirm password
        if(password !== confirmPassword) {
          reject({message: "Password doesn't match. Please try again."})
        }

        jwt.registerUser(displayName, email, password)
          .then(response => {
            // Redirect User
            router.push(router.currentRoute.query.to || '/')

            // Update data in localStorage
            localStorage.setItem("accessToken", response.data.accessToken)
            commit('UPDATE_USER_INFO', response.data.userData, {root: true})

            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },*/
    fetchAccessToken() {
      return new Promise((resolve) => {
        jwt.refreshToken().then(response => { resolve(response) })
      })
    },
  changeUserPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .changePassword(
          payload.userDetails.currentPassword,
          payload.userDetails.newPassword
        )
        .then(response => {
          // If there's user data in response
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },
    verifyEmail({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
            .post("/api/v1/user/verifyAccount", payload)
            .then(res => {
                return resolve(res);
            })
            .catch(err => {
                return reject(err.response);
            });
        });
    },
  forgotPwd({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .forgotPasswordAdmin(payload.userDetail.email)
        .then(response => {
          if (response.data) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({response}) => {
          reject({ message: response.data.message });
        });
    });
  },
    resetPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        jwt
          .resetPassword(payload.userDetails.password, payload.userDetails.token)
          .then(response => {
            // If there's user data in response
            if (response.status == httpStatusCode.OK) {
              // router.push('/admin/dashboard')
              resolve(response);
            } else {
              reject({ message: response.data.message });
            }
          })
          .catch(({ response }) => {
            console.log(response.data.message);
            reject({ message: response.data.message });
          });
      });
    },
    verifyToken({ commit }, token) {
      return new Promise((resolve, reject) => {
        jwt
          .checkForValidToken(token)
          .then(response => {
            if (response.status == httpStatusCode.OK) {
              resolve(response);
            } else {
              reject({ message: response.data.message });
            }
          })
          .catch(({ response }) => {
            reject({ message: response.data.message });
          });
      });
    },
}
